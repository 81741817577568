import React, { Component } from "react";

let data = {};
let transferData;
class Transactions extends Component {



    constructor(props) {
        super(props);

        this.NoServiceModal = React.createRef();
    }

    componentDidMount() {


    }

    state = {
        pending: 0,
    }

    loadUserData = () => {
        data = localStorage.getItem("CURRENT_USER");
        data = JSON.parse(data);
        // console.log(data);
    }

    saveUserDetailsToLocalStorage(data) {
        this.setState({
            pending: data.Pendingtransactions,
        })
        // console.log(data);
        data = JSON.stringify(data)
        localStorage.setItem("user", data);
    }


    render() {
        // const { data } = this.props;
        // console.log(data.Pendingtransactions)
        return (

            this.renderContent()

        );

    }

    renderContent() {
        this.loadUserData();
        // console.log(data)
        if (data.data.Pendingtransactions === 0) {
            // console.log(true)
            return (
                <div className="transaction__container">
                    <div className="transaction__history">
                        <h2 className="heading-secondary">Transaction History &#8595;</h2>
                        <div className="history__container">

                            <table>
                                <caption className="statement__header">Statement Summary</caption>
                                <thead>
                                    <tr>
                                        <th scope="col">Type</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td scope="row" data-label="Type">FUNDS TRANSFER</td>
                                        <td data-label="Description">{`Transfer to ${transferData.bank_name}`}</td>
                                        <td data-label="Amount">{`${transferData.amount}`}</td>
                                        <td data-label="Date">{`${transferData.date}`}</td>
                                        <td data-label="Status">Pending COT Verification</td>
                                    </tr>

                                </tbody>



                            </table>
                        </div>
                    </div>
                </div>

            )
        } else {
            // console.log(false)
            transferData = JSON.parse(data.data.Transaction)
            // console.log(transferData)
            return (
                <div className="transaction__container">
                    <div className="transaction__history">
                        <h2 className="heading-secondary">Transaction History &#8595;</h2>
                        <div className="history__container">

                            <table>
                                <caption className="statement__header">Statement Summary</caption>
                                <thead>
                                    <tr>
                                        <th scope="col">Type</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    
                                    <tr>
                                        <td scope="row" data-label="Type">DEPOSIT</td>
                                        <td data-label="Description">Mobile Deposit</td>
                                        <td data-label="Amount">$2,500,000.00</td>
                                        <td data-label="Date">19/09/2022</td>
                                        <td data-label="Status">Success</td>
                                    </tr>

                                    <tr>
                                        <td scope="row" data-label="Type">DEPOSIT</td>
                                        <td data-label="Description">Mobile Deposit</td>
                                        <td data-label="Amount">$2,500,000.00</td>
                                        <td data-label="Date">27/19/2022</td>
                                        <td data-label="Status">Success</td>
                                    </tr>

                                </tbody>



                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Transactions;