import React, {Component} from "react";
import _ from "lodash";
import Bank1 from '../../img/bank4.jpg';
import Bank2 from '../../img/bank9.jpg';
import Bank3 from '../../img/bank10.jpg';
import Bank4 from '../../img/bank11.jpg';
import Bank5 from '../../img/bank3.jpg';
import { Link } from 'react-scroll';

class ImageSlide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images : [
                Bank1,
                Bank2,
                Bank3,
                Bank4,
                Bank5,
            ],
            numberLine: {
                1: {
                  end: 3,
                }
            }
        }
    }

    componentDidMount() {
        this.imageCounter();
    }

    imageCounter() {
        const that = this;

        function imageTimer() {
            const copy = _.cloneDeep(that.state.numberLine);
            let currentValue = that.state.numberLine["1"].end;
            if (currentValue > 3 ) {
                currentValue = 0;
            } else {
                currentValue++;
            }
            
            copy["1"].end = currentValue;
            that.setState({
                numberLine: copy
              });
              
        }
        setInterval(imageTimer, 7000);
    }


    render() {
        // this.imageCounter()
        return(
            <figure className="image-slide__container" style={{ backgroundImage: Bank1 }}>
                <div className="image-slide__imgwrap" id="imageslide">
                    <img src={this.state.images[`${this.state.numberLine["1"].end}`]} alt="Bank Gallery" className="image-slide__photo"/>
                </div>
                <div className="image-slide__text__box">
                    <p className="image-slide__text__welcome">&#9824; Welcome To <span>True mobile Bank</span></p>
                    <p className="image-slide__text__main">Stay In Control with your <span>finances</span> with Us</p>
                    <p className="image-slide__text__sub">At True mobile Bank, we believe that the beauty of life lies in the little things that give us a better expression of ourselves, 
                        make us more social and connect emotionally.</p>
                        <button className="btn"><Link to="footer" smooth>Contact Us</Link></button>
                </div>
            </figure>
        )
    }
}

export default ImageSlide;