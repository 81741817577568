import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import disableScroll from 'disable-scroll';
import ImageInput from './ImageInput';
import Rodal from 'rodal';
import { doc, updateDoc, getFirestore, collection, getDocs } from "firebase/firestore";

// include styles
import 'rodal/lib/rodal.css';

let USERDATA;
let APPDATA = [];
class QuickTransfer extends Component {

    constructor(props){
        super(props);
        USERDATA = JSON.parse(localStorage.getItem('CURRENT_USER'))
        // console.log(USERDATA)
    }

    state = {
        displayBill: false,
        dimPage: false,
        selectedImg: '',
    }

    componentDidMount() {
        // console.log(this.props.data)
    }

    render() {
        return (
            this.state.displayBill ? (
                <Rodal visible={this.state.displayBill} onClose={() => this.setState({ displayBill: false })}>
                    <div style={{ fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%', flexDirection: 'column' }}>Transaction held. Kindly contact management at <a href="mailto: support@truemobank.org" style={{ textDecoration: 'none', color: 'blue' }}>support@truemobank.org </a>for COST OF TRANSFER</div>
                </Rodal>
            ) : (
                <section className="section-transfer" style={this.props.loading || this.state.dimPage ? { opacity: .15 } : {}}>
                    <div className="transfer__row">
                        <div className="transfer__book">
                            <div className="transfer__book__form">
                                <form action="#" className="transfer__form" onSubmit={(e) => { this.handleSubmit(e) }}>
                                    <div>
                                        <h2 className="transfer-heading">
                                            &#9824; QUICK TRANSFER
                                        </h2>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Bank name" id="bankname" required />
                                        <label for="bankname" className="transfer__form__label">Bank Name</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Country Of Location" id="country" required />
                                        <label for="country" className="transfer__form__label">Country</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Account Name" id="accountname" required />
                                        <label for="accountname" className="transfer__form__label">Account Name</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Account Number" id="accountnumber" required />
                                        <label for="accountnumber" className="transfer__form__label">Account Number</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Routing Number" id="routingnumber" required />
                                        <label for="routingnumber" className="transfer__form__label">Routing Number</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="text" className="transfer__form__input" placeholder="Amount($)" id="amount" required />
                                        <label for="amount" className="transfer__form__label">Amount($)</label>
                                    </div>

                                    <div class="transfer__form__group">
                                        <input type="email" className="transfer__form__input" placeholder="Your Email" id="email" required />
                                        <label for="email" className="transfer__form__label">Your Email</label>
                                    </div>

                                    <div className="form__group">
                                        <button class="btn btn--contact">Transfer Money &rarr;</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            )
        );
    }

    fetchData = async () => {
        const db = getFirestore();
        // console.log(db)
        const querySnapshot = await getDocs(collection(db, "users"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            APPDATA.push({ id: doc.id, data: doc.data() })
        });
        // console.log(APPDATA)
        localStorage.setItem('DATA', JSON.stringify(APPDATA))
        // console.log(USERDATA.data.Username)
        // console.log(USERDATA.data.Password)
        const user = APPDATA.filter(x => x.data.Username === USERDATA.data.Username && x.data.Password === USERDATA.data.Password)
        // console.log(user)
        if (user.length !== 0) {
            // console.log('UPDATED CURRENT USER PROPS')
            localStorage.setItem('CURRENT_USER', JSON.stringify(user[0]))
            this.props.navigate(0);
        }
    }


    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({ dimPage: true });
        disableScroll.on();

        let bankName = event.target[0].value;
        let country = event.target[1].value;
        let accountName = event.target[2].value;
        let accountNumber = event.target[3].value;
        let routingNumber = event.target[4].value;
        let amount = event.target[5].value;
        let email = event.target[6].value;

        /*
        console.log(email);
        console.log(country);
        console.log(bankName);
        console.log(accountName);
        console.log(accountNumber);
        console.log(amount);
        console.log(routingNumber);
        */
        const today = new Date();
        const date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear()
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

        const templateParams = {
            email: email,
            country: country,
            bank_name: bankName,
            account_name: accountName,
            account_number: accountNumber,
            amount: amount,
            routing_number: routingNumber,
            date: date + ' ' + time,
        };

        if (USERDATA.data.Pendingtransactions > 0) {
            disableScroll.off();
            this.setState({ dimPage: false })
            alert('You already have a Pending Transaction. Kindly review and clear it')
        } else {

            const db = getFirestore()
            const userDbRef = doc(db, "users", USERDATA.id);

            await updateDoc(userDbRef, {
                Transaction: JSON.stringify(templateParams),
                Pendingtransactions: 1,
            }).then(() => {
                // console.log('success')

                /*
                Fetch then update all users and currentuserloggedin.
    
                */
                
                this.setState({ dimPage: false, displayBill: true });

                setTimeout(() => {
                    this.fetchData();
                    disableScroll.off();
                }, 5000)

                
            }).catch(error => {
                console.log(error)
                alert('Transaction error. Try Again')
                disableScroll.off();
            });

        }






        /*
        emailjs.send('service_tn7cs9o', 'template_enseduz', templateParams)
            .then((result) => {
                console.log("Message sent");
                setTimeout(() => {
                    this.setState({ dimPage: false, displayBill: true });
                    disableScroll.off();
                }, 3000)

            }, (error) => {
                console.log(error.text);
                disableScroll.off();
            });
            */
    }

}
export default QuickTransfer;