import React, { useState } from 'react'
import { Spinner } from '../../_shared'
import { FaUserCircle } from 'react-icons/fa'
import { IoMail } from 'react-icons/io5'
import { MdFlag, MdOutlineWork, MdGroupWork } from 'react-icons/md'
import { BsHouse, BsFillCalendarWeekFill, BsCurrencyExchange } from 'react-icons/bs'
import { RiGenderlessFill } from 'react-icons/ri'
import { GiPiggyBank } from 'react-icons/gi'
import { Header, Footer } from '../../_shared'

import { collection, addDoc, getFirestore } from "firebase/firestore";
import {useNavigate} from 'react-router-dom'
import DropDown from '../../_shared/DropDown'

function Form() {

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    return (
        <>
        <Header />
        
        <form className='signup' onSubmit={(e) => handleSubmit(e, setLoading, navigate)}>
            <Spinner visible={loading} />
            <div className="signup__form" style={loading ? { display: 'none', width: '0' } : {}}>
                <InputItem
                    placeholder="Full Name"
                >
                    <FaUserCircle className="signup__input__label" />
                </InputItem>

                <InputItem
                    placeholder="Nationality"
                >
                    <MdFlag className="signup__input__label" />
                </InputItem>

                <InputItem
                    placeholder="Country of Residence"
                >
                    <BsHouse className="signup__input__label" />
                </InputItem>

                <InputItem
                    placeholder="Age"
                >
                    <BsFillCalendarWeekFill className="signup__input__label" />
                </InputItem>

                <InputItem
                    placeholder="Gender"
                >
                    <RiGenderlessFill className="signup__input__label" />
                </InputItem>

                <InputItem
                    placeholder="Occupation"
                >
                    <MdOutlineWork className="signup__input__label" />
                </InputItem>

                <InputItem
                    placeholder="Name of Employer"
                >
                    <MdGroupWork className="signup__input__label" />
                </InputItem>

                <InputItem
                    placeholder="Account Type"
                >
                    <GiPiggyBank className="signup__input__label" />
                </InputItem>

                <InputItem
                    placeholder="Email"
                >
                    <IoMail className="signup__input__label" />
                </InputItem>

                <InputItem
                    placeholder="Enter Preferred Currency"
                >
                    <BsCurrencyExchange className="signup__input__label"/>
                </InputItem>


            </div>
            <div className="signup__btnsection" style={loading ? { display: 'none', width: '0' } : {}}>
                <button className="btn signup__btn signup__btn--1" type='submit'>Signup</button>
                <button className="btn signup__btn signup__btn--2">Have An Account</button>
            </div>
        </form>
        <Footer />
        </>
    )
}

const InputItem = (props) => {
    return (
        <div className="signup__inputbox">
            <input
                type="text"
                className="signup__input"
                placeholder={props.placeholder}
            />
            <div className="signup__input__label__box">
                {props.children}
            </div>

        </div>
    )
}

const handleSubmit = async (event, setLoading, navigate) => {
    event.preventDefault();
    setLoading(true)



    let FullName = event.target[0].value;
    let Nationality = event.target[1].value;
    let ResidentCountry = event.target[2].value;
    let Age = event.target[3].value;
    let Gender = event.target[4].value;
    let Occupation = event.target[5].value;
    let EmployerName = event.target[6].value;
    let AccountType = event.target[7].value;
    let Email = event.target[8].value;

    let Currency = event.target[9].value;


    const docRef = await addDoc(collection(getFirestore(), "signedup"), {
        FullName,
        Nationality,
        ResidentCountry,
        Age,
        Gender,
        Occupation,
        EmployerName,
        AccountType,
        Email,
        Currency
    });
    // console.log("Document written with ID: ", docRef.id);

    setLoading(false)
    navigate('/')

}


export default Form;