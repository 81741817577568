import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Home from './Home';

function HomeHoc(props) {
    const navigate = useNavigate();
    const location = useLocation()

    

    // console.log(navigate)
    return <Home navigate={navigate} {...props.children} location={location}/>
    
}

export default HomeHoc;
