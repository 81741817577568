import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import  {  FaMagic} from 'react-icons/fa'
import { Navigator } from '../../_shared'

export class Loan extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Personal Account</h2>
                    <div className='personal__subhead'> Personal Account || Loan Account </div>
                </div>

                <div className='personal__intro'>
                    <h2 className='personal__pagehead'>Loan Account</h2>
                    <p className='personal__description'>True mobile Bank offers a variety of Personal Loans to meet your needs. Please visit with one of our lenders at any of our locations or contact us.</p>
                </div>

               
                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Requirement</h5>
                        <p>True mobile Bank Bank can make a vehicle, mortgage and other loans for you for up to 60 months at very competitive rates. We make used car loans too. So call us or come by and visit with one of our banking associates and we'll help you get that new car, home</p>
                    </div>

                    <div className='personal__body__item'>
                        <h5>Features</h5>
                        <p>Whether you're purchasing a home or remodeling one,True mobile Bank Bank can help guide you through the home loan process. Speak with a mortgage loan expert about borrowing to finance your purchase, refinance, build or access equity in your home at competitive interest rates. We can also assist to purchase or refinance of a property where you are planning to build. Prosperity Bank offers a variety of loan products and terms designed to meet your specific needs including 15 and 30 year mortgages, VA, FHA and more.</p>
                    </div>

                    

                    
                </div>

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default Loan
