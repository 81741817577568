import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import { Link as ScrollLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { Navigate } from 'react-router'


export class DropDown extends Component {
    state={
        switchPageToLogin: false,
        navigateToSignup: false,
    }

    goToLogin() {
        // const location = useLocation();
        // console.log(window.location.pathname.length);
        if (window.location.pathname !== '/') {
            this.setState({ switchPageToLogin: true })
        } else {
            // console.log(document.getElementById('login-section').getBoundingClientRect().top)
            scroll.scrollTo(document.getElementById('login-section').getBoundingClientRect().top)
        }
       

    }

    render() {
        const { switchPageToLogin, navigateToSignup } = this.state
        return (
            <section className='dropdown'>
                {switchPageToLogin && (
                     <Navigate to="/signup" state={{ goToLogin: true}} />
        )}  

        {
            navigateToSignup && (
                <Navigate to="/signup" replace={true} />
              )
        }

                <div className='dropdown__menucontainer'>
                    <div className="dropdown__menu">
                        <Link to='/' style={styles.menulink}>
                            <p> Home </p>
                        </Link>
                        
                    </div>

                    <div className="dropdown__menu">
                        <p>Personal</p>
                        
                        <ul className='dropdown__menuItems'>
                            <li><Link to='/current' style={styles.sublink} className='dropdown__menuItems__list'>Current Account</Link></li>
                            <li><Link to='/saving' style={styles.sublink} className='dropdown__menuItems__list'>Saving Account</Link></li>
                            <li><Link to='/premium' style={styles.sublink} className='dropdown__menuItems__list'>Premium Account</Link></li>
                            <li><Link to='/kid' style={styles.sublink} className='dropdown__menuItems__list'>Kid Account</Link></li>
                            <li><Link to='/loan' style={styles.sublink} className='dropdown__menuItems__list'>Loan Account</Link></li>
                        </ul>
                    </div>
                    <div className="dropdown__menu">
                        <p>Business</p>
                        
                        <ul className='dropdown__menuItems'>
                            <li><Link to='/business' style={styles.sublink} className='dropdown__menuItems__list'>Business Account</Link></li>
                            <li><Link to='/insurance' style={styles.sublink} className='dropdown__menuItems__list'>Insurance</Link></li>
                            <li><Link to='/investment' style={styles.sublink} className='dropdown__menuItems__list'>Investment</Link></li>
                        </ul>
                    </div>
                    <div className="dropdown__menu">
                        <p>Corporate</p>
                        
                        <ul className='dropdown__menuItems'>
                            <li><Link to='/corporateintro' style={styles.sublink} className='dropdown__menuItems__list'>Overview</Link></li>
                            <li><Link to='/corporate' style={styles.sublink} className='dropdown__menuItems__list'>Corporate Account</Link></li>
                        </ul>

                    </div>
                    <div className="dropdown__menu">
                        <p>Who we are</p>
                        
                        <ul className='dropdown__menuItems'>
                            <li><Link to='/overview' style={styles.sublink} className='dropdown__menuItems__list'>Overview</Link></li>
                            <li><Link to='/mission' style={styles.sublink} className='dropdown__menuItems__list'>Our Mission</Link></li>
                            <li><Link to='/vision' style={styles.sublink} className='dropdown__menuItems__list'>Our Value</Link></li>
                            <li><Link to='/directors' style={styles.sublink} className='dropdown__menuItems__list'>Board of Directors</Link></li>
                            <li>Frequently Asked Questions</li>
                        </ul>

                    </div>
                    <div className="dropdown__menu">
                        <p>Contact</p>
                        
                    </div>
                    <div className="dropdown__menu">
                        <p>Internet Banking</p>
                        
                        <ul className='dropdown__menuItems'>
                            <li onClick={() => this.goToLogin()}>Login</li>
                            <li onClick={() => this.setState({ navigateToSignup: true })}>Open Account</li>

                        </ul>
                    </div>
                </div>





            </section>
        )
    }
}

export default DropDown

const styles = {
    menulink: {
        textDecoration: 'none',
        color: '#fff',
    },

    sublink: {
        textDecoration: 'none',

    }
}
