import React, { Component } from 'react'
import Logo from '../img/logo.png';
import Time from '../img/stopwatch.png';
import Phone from '../img/telephone.png';
import Email from '../img/email.png';

export class Header extends Component {
    render() {
        return (
            <header className='header'>

                <div className="header__logobox">
                    <img src={Logo} alt="logo" className="header__logo" />
                </div>

                <div className="header__item">
                    <div className='header__item__imgbox'>
                        <img src={Time} alt="Header item descriptor" className="header__item__img" />
                    </div>
                    <div className='header__item__desc'>
                        <h3 className='header__item__head'>Opening Time</h3>
                        <p className='header__item__text'>Mon-Sat: 9.00-18.00</p>
                    </div>
                </div>

                <div className="header__item">
                    <div className='header__item__imgbox'>
                        <img src={Email} alt="Header item descriptor" className="header__item__img" />
                    </div>

                    <div className='header__item__desc'>
                        <a href="mailto: support@truemobank.org" style={{ textDecoration: 'none', color: 'currentcolor' }}>


                            <h3 className='header__item__head'>Email address</h3>
                            <p className='header__item__text' style={{ textTransform: 'lowercase' }}>Support@truemobank.org</p>
                        </a>
                    </div>
                </div>

                <div className="header__item">
                    <div className='header__item__imgbox'>
                        <img src={Phone} alt="Header item descriptor" className="header__item__img" />
                    </div>

                    <div className='header__item__desc'>
                        <h3 className='header__item__head'>Phone</h3>
                        <p className='header__item__text'>+1 856-332-8726</p>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header
