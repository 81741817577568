import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import  {  FaMagic} from 'react-icons/fa'
import { Navigator } from '../../_shared'

export class CorporateIntro extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Corporate Account</h2>
                    <div className='personal__subhead'> Corporate Account </div>
                </div>

               
                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Overview</h5>
                        <p>We provide a range of services to our target clients across our product groups of Transaction Banking, cash management services, Trade Finance, Business Finance and Corporate Finance (including Corporate Advisory, Project & Export Finance, Structured Trade Finance, Structured Finance). These services are offered from our local expertise based on global insights. These Products and Services are used to deliver the best banking solutions to both existing and potential clients. To effectively deliver these services, True mobile Bank corporate clients have been categorized into Corporates, Multinationals, Large Domestic Companies and Institutions.</p>
                    </div>
                    
                </div>

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default CorporateIntro
