import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAb4QnmljHEckf4uYxj0YicKk-cAJ_xVSE",
    authDomain: "gacu-bfbca.firebaseapp.com",
    databaseURL: "https://gacu-bfbca.firebaseio.com",
    projectId: "gacu-bfbca",
    storageBucket: "gacu-bfbca.appspot.com",
    messagingSenderId: "698624517571",
    appId: "1:698624517571:web:a650cc30f0ff7390898976",
    measurementId: "G-FE6K3FBFRT"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)

export default db;