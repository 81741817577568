import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Landing from './Landing';

function LandingHoc(props) {
    const navigate = useNavigate();
    const location = useLocation();
    
    
    
    
    // console.log(navigate)
    return <Landing navigate={navigate} {...props.children} location={location}  />
    
}

export default LandingHoc;
