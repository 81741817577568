import { createChatBotMessage } from 'react-chatbot-kit';
import Chatbot2 from '../../img/chatbot2.png';

const Config = {
    botName: 'COOPBot',
    initialMessages: [createChatBotMessage(`Welcome To True mobile Bank Help Bot. How May I Be Of Help Please?`)],
    customComponents: {
        // Replaces the default header
       
       botAvatar: (props) => <MyAvatar {...props} />,
       
     },
};

export default Config;

const MyAvatar = () => {
    return (
        <img className='landing__botbox__customicon' alt="Bot default avatar" src={Chatbot2}/>
    )
}