import React from "react";
import { Navigate, Route } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const loggedIn = parseInt(localStorage.getItem('LOGIN_STATE'));
    // console.log(loggedIn)
    if (!loggedIn) {
      return <Navigate to="/" replace />;
    }
  
    return children;
    
  };
export default ProtectedRoute;


