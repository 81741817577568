import React, { Component } from 'react';
import Logo from "../img/logo.png";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

class Footer extends Component {
    render() {
        return (
            <Element name="footer" className="footer">
                <div className="footer__logo-section">
                    <div className="footer__logo-box">
                        <img src={Logo} alt="Logo of the company" className="footer__logo" />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <p>
                            True mobile International Bank,<br /> 842 Lee Avenue  <br /> Camden, New Jersey
                        </p>

                        <p>
                            truemobank.org
                        </p>
                        <p>
                            +1 856-332-8726
                        </p>
                        

                        <a href="mailto: support@truemobank.org" style={{ textDecoration: 'none', color: 'currentcolor', fontWeight: 'bold' }}>
                            Email Us:
                        </a>

                        <p>
                            <a href="mailto: support@truemobank.org">support@truemobank.org</a>
                        </p>
                    </div>

                </div>

                <div className="footer__aboutus">
                    <div className="footer__headers">
                        <h4>About Us</h4>
                    </div>
                    <div className="footer__items">
                        <p>Careers</p>
                        <p>Blog</p>
                        <p>Team</p>
                        <p>Contact Us</p>
                    </div>

                </div>

                <div className="footer__service">
                    <div className="footer__headers">
                        <h4>Our Service</h4>
                    </div>
                    <div className="footer__items">
                        <p>Internet Banking</p>
                        <p>Home Loans</p>
                        <p>Currency rates</p>
                        <p>Mastercard</p>
                    </div>
                </div>

                <div className="footer__download">
                    <div className="footer__headers">
                        <h4>Download our App</h4>
                    </div>
                    <div className="footer__items">
                        <p>
                            Sorry Clients. Our mobile app is still in progress and will be launched as soon as its complete on
                            both Google Playstore and Apple App Store soon. We encourage you to keep using our online services available here.
                        </p>
                    </div>
                </div>

            </Element>
        );
    }
}

export default Footer;