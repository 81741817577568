import React, { Component } from 'react';
import { CgHomeScreen } from "react-icons/cg";
import { FaRegCreditCard } from "react-icons/fa";
import { GoHome } from "react-icons/go";
import { FaBusinessTime } from "react-icons/fa";
import { FaExchangeAlt } from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";
import { Element } from 'react-scroll';

class Services extends Component {
    render() {
        return(
            <Element className="services" name="services">
                <h3 className="services__header">&#9824; Best Services</h3>
                <h2 className="services__subheader">Entirely Providing Best <span>Services</span></h2>
                <p className="services__description">Expanding banking activities to include new sectors and economic activities; such as agriculture, industry, trade and
                    services with full time customer service support
                </p>
                <div className="services__container">
                    <div className="services__card">
                        <CgHomeScreen className="services__card__icon"/>
                        <h4 className="services__card__title">Mobile Banking</h4>
                        <p className="services__card__text">
                        A service provided by our financial instituition that allows its customers to conduct financial 
                        transactions remotely using a mobile device such as a smartphone, tablet or even a computer over the internet.
                        </p>
                    </div>

                    <div className="services__card">
                        <FaBusinessTime className="services__card__icon"/>
                        <h4 className="services__card__title">online business</h4>
                        <p className="services__card__text">
                           Manage your businesses online with our bank. We provide secured endpoints for your online transactions into the bank account.
                           With our high security service, you are guaranteed of fraud and hack free transactions over the internet.
                        </p>
                    </div>

                    <div className="services__card">
                        <GoHome className="services__card__icon"/>
                        <h4 className="services__card__title">home loan</h4>
                        <p className="services__card__text">
                        We have enhanced our home loan offerings, allowing us to offer you more! These additions to our borrowing products seek to provide financial
                        assistance to anyone looking to purchase, improve or capitalise from their property - whether they are here on United Kingdom soil, or abroad.
                        </p>
                    </div>

                    <div className="services__card">
                        <FaRegCreditCard className="services__card__icon"/>
                        <h4 className="services__card__title">Your master card</h4>
                        <p className="services__card__text">
                        Wherever you are, you can always count on Mastercard Global Services for all your needs. Call us 24 hours a day, 365 days a year.
                        Our multi lingual representatives are waiting to help you with lost card reporting and replacement and all other needs
                        </p>
                    </div>

                    <div className="services__card">
                        <FaExchangeAlt className="services__card__icon"/>
                        <h4 className="services__card__title">Money exchange</h4>
                        <p className="services__card__text">
                        True mobile Bank Bank currency calculator tools use the best rates, the touchstone foreign exchange rates compiled from leading market data contributors.
                        Our rates are trusted and used by major corporations, tax authorities, auditing firms, and individuals around the world.
                        </p>
                    </div>

                    <div className="services__card">
                        <CgFileDocument className="services__card__icon"/>
                        <h4 className="services__card__title">Farmer Packages</h4>
                        <p className="services__card__text">
                        We service bilateral, club and syndicated loans packages to farmers. We represent either a group of lenders on a single loan through our Loan Agency services, or lenders
                        (including direct lenders) with portfolios of loans through our Loan Servicing proposition.

                        </p>
                    </div>

                </div>
                
            </Element>
        );
    }
}

export default Services;