import './App.scss';
import React, { Component } from 'react';
import {Landing} from './features/Landing';
import {Home} from './features/Home';
import { Signup } from './features/Landing';
import { BrowserRouter as Router, Route,Routes } from "react-router-dom";
import ProtectedRoute from './ProtectedRoute';
import { Personal, Saving, Premium, Loan, Kid, Business, Insurance, Investments, Corporate, CorporateIntro, Mission, Vision, Overview, Directors } from './features/Tabs'


// import { initializeApp } from "firebase/app";


// import emailjs from 'emailjs-com';

class App extends Component {

  

  componentDidMount() {

    
    // firebaseApp();
    // this.fetchData()
    // console.log(this.props.navigate)
}



  render() {
    return (
      <Router>
        <Routes>
          <Route exact  path="/" element={<Landing />} />
          <Route
          path="home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
          <Route  path="/current" element={<Personal />} />
          <Route  path="/premium" element={<Premium />} />
          <Route  path="/loan" element={<Loan />} />
          <Route  path="/saving" element={<Saving />} />
          <Route  path="/kid" element={<Kid />} />
          <Route  path="/insurance" element={<Insurance />} />
          <Route  path="/investment" element={<Investments />} />
          <Route  path="/business" element={<Business />} />
          <Route  path="/corporateintro" element={<CorporateIntro />} />
          <Route  path="/corporate" element={<Corporate />} />
          <Route  path="/overview" element={<Overview />} />
          <Route  path="/mission" element={<Mission />} />
          <Route  path="/vision" element={<Vision />} />
          <Route  path="/directors" element={<Directors />} />
          <Route  path="/signup" element={<Signup />} />

        </Routes>
      </Router>
      
    );
  }
  
}

export default App;
