import React, { Component } from 'react'
import { Header, Dropdown, Footer } from '../../_shared/index';
import  {  FaMagic} from 'react-icons/fa'
import { Navigator } from '../../_shared'

export class Overview extends Component {
    render() {
        return (
            <div className='personal'>
                <Header />
                <Dropdown />

                <div className='personal__header'>
                    <h2>Who are We</h2>
                    <div className='personal__subhead'> Welcome To True mobile Bank </div>
                </div>

                <div className='personal__intro'>
                    <h2 className='personal__pagehead'>Introduction</h2>
                    <p className='personal__description'>True mobile Bank Bank, we're committed to providing services that will simplify our customer's everyday financial needs. We believe in building genuine relationships with a handshake, providing positive experiences at every touch point from in-person to our digital channels, and backing our customers information and financial records with the highest level of security. You’ll find knowledgeable associates ready to serve with accuracy and a smile time and time again. And we proudly care for cities, large and small in the communities we serve.</p>
                </div>

               
                <div className='personal__body'>
                <FaMagic className='personal__body__icon'/>
                    <div className='personal__body__item'>
                        <h5>Choose Us</h5>
                        <p>True mobile Bank Bank  received its Universal Banking License in 2002 and soon commenced providing specialized retail banking services. With its highly skilled professional staff who emphasize the delivery of efficient and excellent customer services, True mobile Bank continues to provide a broad range of banking and financial solutions to large corporations, small and medium-sized enterprises, public sector institutions and retail customers accross the country</p>
                    </div>

                    <div className='personal__body__item'>
                        <h5>Happy Customers</h5>
                        <p>We will always take the high road by practicing the utmost ethical standards, and by honoring our commitments. We are dedicated to the practice of choosing to be forthright and honest; this is who we are. We seek to establish mutually beneficial, caring relationships based on trust and the desire for mutual success.
                        We will cultivate excellence by challenging ourselves with courage and confidence. We will seek to inspire a fun environment where innovation thrives, mentoring is essential, and our journey is celebrated. We will strive to deliver a legendary experience through our unrivaled service.
                        </p>
                    </div>

                    

                    
                </div>

                <div className='personal__contact'>
                        <p>We Care For Our Customers Always, Our Customers Are Our Focus.</p>
                        <button className="btn btn--contact"> Contact Us </button>
                </div>

                <Footer />
                <Navigator />
            </div>
        )
    }
}

export default Overview
